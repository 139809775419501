<template>
  <div>
    <div class="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
      <input
        :id="id"
        :checked="selectedIds.indexOf(value) !== -1 || isChecked"
        :value="value"
        class="relative float-left mt-[0.15rem] mr-[6px] -ml-[1.5rem] h-[1.125rem] w-[1.125rem] rounded-[0.25rem] border-[0.125rem]"
        type="checkbox"
        v-bind="$attrs"
        @change="handleCheckBox"
      />
    </div>
  </div>
</template>
<script setup>
defineOptions({
  inheritAttrs: false
});
defineProps({
  isCheckedAll: {
    type: Boolean,
    default: false
  },
  value: {
    type: [String, Number],
    default: ''
  },
  modelValue: {
    type: Boolean,
    default: true
  },
  isChecked: {
    type: Boolean,
    default: false
  },
  selectedIds: {
    type: Array,
    default: () => []
  },
  id: {
    type: String,
    required: true
  }
});
const emit = defineEmits(['handleCheckBox', 'update:modelValue']);

const handleCheckBox = (e) => {
  emit('update:modelValue', e);
  emit('handleCheckBox', e);
};
</script>
